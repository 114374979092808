.session-container {
  width: 100%;
  position: relative;
  /* align-items: flex-start; */
  display: flex;
  padding: 12px 8px;
  border-bottom: 1px solid #cdd8e6;
  cursor: pointer;
}

.session-container .option-delete-btn {
  border: none;
  background: transparent;
  right: 0;
  cursor: pointer;
  font-size: 10px;
}

.session-container .session-content {
  flex-grow: 1;
  font-size: 0.8rem;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.session-container .session-time {
  font-size: 8px;
  color: #5f5d73;
  margin-left: 10px;
  text-align: right;
  min-width: 80px;
  padding-top: 6px;
  box-sizing: border-box;
}

.session-container:hover {
  background-color: #f8f8f7;
}

.session-separator {
  margin: 0;
  border-color: rgba(255, 255, 255, 0.7);
}

.session-container i {
  font-size: 1.1rem;
  color: #1e4c88;
}

body {
  margin: 0;
  font-family: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #07032b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Muli regular */
@font-face {
  font-family: "Muli";
  src: url("./assets/fonts/Muli/Muli-Regular.eot");
  src: url("./assets/fonts/Muli/Muli-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Muli/Muli-Regular.woff2") format("woff2"),
    url("./assets/fonts/Muli/Muli-Regular.woff") format("woff"),
    url("./assets/fonts/Muli/Muli-Regular.ttf") format("truetype"),
    url("./assets/fonts/Muli/Muli-Regular.svg") format("svg");
  font-weight: 400;
  font-style: normal;
}

/* Muli medium */
@font-face {
  font-family: "Muli";
  src: url("./assets/fonts/Muli/Muli-Medium.eot");
  src: url("./assets/fonts/Muli/Muli-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Muli/Muli-Medium.woff2") format("woff2"),
    url("./assets/fonts/Muli/Muli-Medium.woff") format("woff"),
    url("./assets/fonts/Muli/Muli-Medium.ttf") format("truetype"),
    url("./assets/fonts/Muli/Muli-Medium.svg") format("svg");
  font-weight: 500;
  font-style: normal;
}

/* Muli semibold */
@font-face {
  font-family: "Muli";
  src: url("./assets/fonts/Muli/Muli-SemiBold.eot");
  src: url("./assets/fonts/Muli/Muli-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Muli/Muli-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Muli/Muli-SemiBold.woff") format("woff"),
    url("./assets/fonts/Muli/Muli-SemiBold.ttf") format("truetype"),
    url("./assets/fonts/Muli/Muli-SemiBold.svg") format("svg");
  font-weight: 600;
  font-style: normal;
}

/* Muli bold */
@font-face {
  font-family: "Muli";
  src: url("./assets/fonts/Muli/Muli-Bold.eot");
  src: url("./assets/fonts/Muli/Muli-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Muli/Muli-Bold.woff2") format("woff2"),
    url("./assets/fonts/Muli/Muli-Bold.woff") format("woff"),
    url("./assets/fonts/Muli/Muli-Bold.ttf") format("truetype"),
    url("./assets/fonts/Muli/Muli-Bold.svg") format("svg");
  font-weight: 700;
  font-style: normal;
}

/* Muli extra-bold */
@font-face {
  font-family: "Muli";
  src: url("./assets/fonts/Muli/Muli-ExtraBold.eot");
  src: url("./assets/fonts/Muli/Muli-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Muli/Muli-ExtraBold.woff2") format("woff2"),
    url("./assets/fonts/Muli/Muli-ExtraBold.woff") format("woff"),
    url("./assets/fonts/Muli/Muli-ExtraBold.ttf") format("truetype"),
    url("./assets/fonts/Muli/Muli-ExtraBold.svg") format("svg");
  font-weight: 800;
  font-style: normal;
}

/* Muli black */
@font-face {
  font-family: "Muli";
  src: url("./assets/fonts/Muli/Muli-Black.eot");
  src: url("./assets/fonts/Muli/Muli-Black.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Muli/Muli-Black.woff2") format("woff2"),
    url("./assets/fonts/Muli/Muli-Black.woff") format("woff"),
    url("./assets/fonts/Muli/Muli-Black.ttf") format("truetype"),
    url("./assets/fonts/Muli/Muli-Black.svg") format("svg");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "icomoon";
  src: url("./assets/fonts/icomoon.eot");
  src: url("./assets/fonts/icomoon.eot") format("embedded-opentype"),
    url("./assets/fonts/icomoon.ttf") format("truetype"),
    url("./assets/fonts/icomoon.woff") format("woff"),
    url("./assets/fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-left-thin:before {
  content: "\e900";
}
.icon-cross:before {
  content: "\e901";
}
.icon-bulb:before {
  content: "\e902";
  color: #f9b234;
}
.icon-thumbs-down:before {
  content: "\e903";
  color: #b10f0f;
}
.icon-thumbs-up:before {
  content: "\e904";
  color: #108e68;
}
.icon-close:before {
  content: "\e905";
  color: #c7c7be;
}
.icon-up-chevron:before {
  content: "\e906";
  color: #07032b;
}
.icon-down-chevron:before {
  content: "\e907";
  color: #07032b;
}
.icon-left-chevron:before {
  content: "\e908";
  color: #07032b;
}
.icon-bookmark:before {
  content: "\e909";
}
.icon-warning:before {
  content: "\e90a";
  color: #c73131;
}
.icon-add:before {
  content: "\e90b";
  color: #1e4c88;
}
.icon-download:before {
  content: "\e90c";
  color: #1e4c88;
}
.icon-upload:before {
  content: "\e90d";
  color: #f9b234;
}
.icon-forward-arrow:before {
  content: "\e90e";
  color: #07032b;
}
.icon-home:before {
  content: "\e90f";
}
.icon-left-arrow:before {
  content: "\e910";
  color: #d5d5d5;
}
.icon-right-arrow:before {
  content: "\e911";
  color: #07032b;
}
.icon-send:before {
  content: "\e912";
  color: #dbdbdb;
}
.icon-attach-file:before {
  content: "\e913";
  color: #dbdbdb;
}
.icon-add-simple:before {
  content: "\e914";
  color: #1e4c88;
}
.icon-form:before {
  content: "\e915";
  color: #c37943;
}
.icon-gift:before {
  content: "\e916";
}
.icon-back-arrow:before {
  content: "\e917";
  color: #07032b;
}
.icon-calendar:before {
  content: "\e918";
  color: #fff;
}
.icon-tick:before {
  content: "\e919";
  color: #26ad6a;
}
.icon-template:before {
  content: "\e91a";
  color: #39b7ef;
}
.icon-refresh:before {
  content: "\e91b";
}
.icon-power:before {
  content: "\e91c";
}
.icon-delete:before {
  content: "\e91d";
}
.icon-text:before {
  content: "\e91e";
}
.icon-date:before {
  content: "\e91f";
}
.icon-para:before {
  content: "\e920";
}
.icon-menu:before {
  content: "\e921";
}
.icon-radio:before {
  content: "\e922";
}
.icon-checkbox:before {
  content: "\e923";
}
.icon-earth:before {
  content: "\e924";
  color: #07032b;
}
.icon-copy:before {
  content: "\e925";
  color: #07032b;
}
.icon-check:before {
  content: "\e926";
  color: #1e4c88;
}
.icon-edit:before {
  content: "\e927";
}
.icon-move-down:before {
  content: "\e928";
}
.icon-move-up:before {
  content: "\e929";
}
.icon-new:before {
  content: "\e92a";
}
.icon-quote:before {
  content: "\e92b";
}
.icon-fullscreen:before {
  content: "\e92c";
}
.icon-line-spacing:before {
  content: "\e92d";
}
.icon-indent:before {
  content: "\e92e";
}
.icon-outdent:before {
  content: "\e92f";
}
.icon-number-list:before {
  content: "\e930";
}
.icon-highlight:before {
  content: "\e931";
}
.icon-text-color:before {
  content: "\e932";
}
.icon-redo:before {
  content: "\e933";
}
.icon-undo:before {
  content: "\e934";
}
.icon-view:before {
  content: "\e935";
}
.icon-video:before {
  content: "\e936";
}
.icon-image:before {
  content: "\e937";
}
.icon-link:before {
  content: "\e938";
}
.icon-bullet-list:before {
  content: "\e939";
}
.icon-italic:before {
  content: "\e93a";
}
.icon-underline:before {
  content: "\e93b";
}
.icon-bold:before {
  content: "\e93c";
}
.icon-more:before {
  content: "\e93d";
}
.icon-calendar-line:before {
  content: "\e93e";
}
.icon-setting:before {
  content: "\e93f";
}
.icon-user-line:before {
  content: "\e940";
}
.icon-thumbs-down-line:before {
  content: "\e941";
}
.icon-tumbs-up-line:before {
  content: "\e942";
}
.icon-tips:before {
  content: "\e943";
}
.icon-download-line:before {
  content: "\e944";
}
.icon-upload-line:before {
  content: "\e945";
}
.icon-password:before {
  content: "\e946";
}
.icon-username:before {
  content: "\e947";
}
.icon-logout-line:before {
  content: "\e948";
}
.icon-login-line:before {
  content: "\e949";
}
.icon-help-line:before {
  content: "\e94a";
}
.icon-save-line:before {
  content: "\e94b";
}
.icon-create-line:before {
  content: "\e94c";
}
.icon-send-line:before {
  content: "\e94d";
}
.icon-email-line:before {
  content: "\e94e";
}
.icon-emoji:before {
  content: "\e94f";
}
.icon-call-line:before {
  content: "\e950";
}
.icon-chevron-right:before {
  content: "\e951";
}
.icon-chevron-left1:before {
  content: "\e952";
}
.icon-chevron-down:before {
  content: "\e953";
}
.icon-chevron-up:before {
  content: "\e954";
}
.icon-bell:before {
  content: "\e955";
}
.icon-document:before {
  content: "\e956";
}
.icon-star-outline:before {
  content: "\e957";
}
.icon-star:before {
  content: "\e958";
}
.icon-tick-line:before {
  content: "\e959";
}
.icon-security:before {
  content: "\e95a";
}
.icon-alternate_email:before {
  content: "\e95b";
}
.icon-eye-off:before {
  content: "\e95c";
}
.icon-link-2:before {
  content: "\e95d";
}
.icon-code:before {
  content: "\e95e";
}
.icon-lab:before {
  content: "\e95f";
}
.icon-flow-tree:before {
  content: "\e960";
}
.icon-alert-circle:before {
  content: "\e961";
}
.icon-edit-line:before {
  content: "\e962";
}
.icon-number:before {
  content: "\e963";
}
.icon-number1:before {
  content: "\e964";
}
.icon-number2:before {
  content: "\e965";
}
.icon-number3:before {
  content: "\e966";
}
.icon-number4:before {
  content: "\e967";
}
.icon-number5:before {
  content: "\e968";
}
.icon-number6:before {
  content: "\e969";
}
.icon-number7:before {
  content: "\e96a";
}
.icon-number8:before {
  content: "\e96b";
}
.icon-form-line:before {
  content: "\e96c";
}
.icon-puzzle:before {
  content: "\e96d";
}
.icon-user-management:before {
  content: "\e96e";
}
.icon-plus-circle:before {
  content: "\e96f";
}
.icon-handshake:before {
  content: "\e970";
}
.icon-antenna:before {
  content: "\e971";
}
.icon-brain:before {
  content: "\e972";
}
.icon-dashboard:before {
  content: "\e973";
}
.icon-flowchart:before {
  content: "\e974";
}
.icon-minus-circle:before {
  content: "\e975";
}
.icon-search:before {
  content: "\e976";
}
.icon-branch:before {
  content: "\e977";
}
.icon-strike:before {
  content: "\ea65";
}
.icon-superscript:before {
  content: "\ea69";
}
.icon-subscript:before {
  content: "\ea6a";
}
.icon-table:before {
  content: "\ea71";
}

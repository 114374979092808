:root {
  --primary-color: #0923e6;
  --primary-text-color: #ffffff;
  --user-color: #c5d2e3;
  --user-text-color: #07032b;
  --button-color: #c5d2e3;
  --button-text-color: #07032b;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.flex-one {
  flex: 1;
}

.clearfix {
  height: 16px;
}

.half-clearfix {
  height: 16px;
}

.quarter-clearfix {
  height: 8px;
}

.right-align {
  text-align: right;
}

.left-align {
  text-align: left;
}

.page-subheading {
  font-weight: bold;
  font-size: 0.8rem;
  letter-spacing: 0.03em;
  text-align: left;
  margin: 0 0 12px;
}

.side-padding {
  padding: 0 16px;
}
